<template>
    <Overview 
        :moduleName="this.formatModuleName(this.$route.name, this.$i18n, false)"
        :moduleNamePlural="this.formatModuleName(this.$route.name, this.$i18n)"
        :sort="'id'"
        :sortOrder=-1
        :itemService=itemService
        :itemFormRef=itemFormRef
        :selectFields=selectFields
        :showAddButton=false
        :showAction=true
        :showDropdown=false
    >
        <template #columns>
            <Column field="created_at" :header="$t('overview.date')" style='width:15%'>
                <template #body="slotProps">
                    {{formatDate(slotProps.data.created_at, 'dateTimeHistory')}}
                </template>
            </Column>
            <Column field="user_name" :header="$t('navigation.users')" style='width:20%'></Column>
            <Column field="formatted_log" :header="$t('overview.content')" ></Column>
        </template>

        <template #form>
            <LogForm ref="ItemForm" :itemData="item" />
        </template>
    </Overview>
    
</template>

<script>
import Overview from '@/components/Overview.vue';
import Column from '@/components/column';
import LogForm from '@/components/log/LogForm.vue';
import logService from '@/services/LogService';
import store from '@/store/user';

export default {
    components: {
        Overview,
        Column,
        LogForm
    },
    data() {
        return {
            item: {},
            itemService: logService,
            itemFormRef: null,
            selectFields: [
                'id',
                'created_at',
                'controller',
                'method',
                'body',
                'user_id',
                "user_name"
            ]
        }
    },
    mounted() {
        if(!store.getters.getCompanyHq){
            window.location.href = '/';
            return;
        }
        this.itemFormRef = this.$refs.ItemForm;
    }
}
</script>